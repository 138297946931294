import {mapMutations, mapGetters, mapActions} from 'vuex';
import Vue from 'vue'
import timeItem from "@/modules/card/components/time-item/index.vue";
// import moment from 'vue-moment'
Vue.use(require('vue-moment'));

export default {
  name: 'product',

  props: {
    product: {
      type: Object,
      default: () => {
      }
    },
    categorySlug: {
      type: String,
      default: () => 'all'
    },
    typePage: {
      type: String,
      default: () => ''
    },
  },
  // props: ['product', 'category_id', 'typePage'],
  data() {
    return {
      like: false,
      promotionTimeEnded: false,
      startTime: '',
      endTime: '',
      timeinterval: undefined,
      times: [
        {id: 0, text: this.$t('days'), time: 0},
        {id: 1, text: ":", time: 1},
        {id: 2, text: ":", time: 1},
        {id: 3, text: ":", time: 1}
      ],
    }
  },
  computed: {
    ...mapGetters({
      basket: `basket/basket`,
      count: `basket/count`,
      showPopupAddBasket: 'basket/showPopupAddBasket',
      isAuthenticated: `auth/isAuthenticated`,
      whichList: 'favorites/whichList',
    }),
    productWeight() {
      if (this.product.weights) {
        return this.product.weights[0].weight
      }
    },
    discountPrice() {
      if (this.product.weights) {
        return this.product.weights[0].sale.new_price
      }
    },
    currentPrice() {
      if (this.product.weights) {
        return this.product.weights[0].price
      }
    },
    percentDiscount() {
      if (this.product.weights) {
        return this.product.weights[0].sale.percent_discount
      }
    },
    dateFrom() {
      if (this.product.weights) {
        return this.product.weights[0].sale.date_from
      }
    },
    isSaleProduct() {
      return this.product.weights.find(el => el.sale.percent_discount)
    },
    checkFavorite() {
      return this.whichList.find(e => e.id === this.product.id)
    },
    calculateTimeSale() {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(this.product.weights[0].sale.date_to)
      const secondDate = new Date();
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays
    }
  },
  mounted() {
    let lang = this.$route.params.lang
    if (this.times[0].time === 0) {
      this.times[0].text = this.$t('days')
      this.times[1].text = this.$t('hours')
      this.times[2].text = this.$t('minutes')
      this.times[3].text = this.$t('seconds')
    }
    switch (this.times[0].time) {
      case 1 || 31:
        this.times[0].text = 'день'
        break;
      case lang === 'ru' && this.times[0].time !== 1 && this.times[0].time !== 31:
        this.times[0].text = 'дней'
        break;
      case lang === 'ua' && this.times[0].time !== 1 && this.times[0].time !== 31 :
        this.times[0].text = 'днів'
        break;
      case lang === 'ua' && this.times[0].time >= 2 && this.times[0].time <= 24:
        this.times[0].text = 'дні'
        break;
      case lang === 'ru' && this.times[0].time >= 2 && this.times[0].time <= 24:
        this.times[0].text = 'дня'
        break;
      default:
        this.times[0].text = 'день'
    }

    this.startTime = this.product.weights[0].sale.date_from
    this.endTime = this.product.weights[0].sale.date_to
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);

  },
  //
  watch: {
    product: {
      deep: true,
      handler() {

      }
    },

  },
  components: {
    timeItem
  },

  methods: {

    ...mapActions({
      addFavourite: `favorites/ADD_PRODUCT_TO_FAVORITE`,
      removeItemFromFavourites: `favorites/REMOVE_PRODUCT_IN_FAVORITE`,
      fetchFavoriteList: `favorites/GET_FAVORITES_LIST`,
    }),


    ...mapMutations({
      addBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeShowPopupAddBasket: 'basket/CHANGE_POPUP_ADD_BASKET',
      changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
      changeFastBuy: `card/CHANGE_POPUP_FAST_BUY`,
      setProduct: 'home/SET_FAST_BUY_PRODUCT'
    }),
    updateTimer: function () {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
        this.updateProgressBar();
      } else {
        clearTimeout(this.timeinterval);
        this.promotionTimeEnded = true
        // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    getTimeRemaining: function () {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
      if (t >= 0) {
        this.times[3].time = Math.floor(t / 1000 % 60); //seconds
        this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
        this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    updateProgressBar: function () {
      let startTime = Date.parse(new Date(this.startTime));
      let currentTime = Date.parse(new Date());
      let endTime = Date.parse(new Date(this.endTime));
      let interval = parseFloat(
        (currentTime - startTime) / (endTime - startTime) * 100
      ).toFixed(2);
      this.progress = 100 - interval;
    },
    setVolume(vol) {
      let type;
      switch (vol.type) {
        case 'миллилитры':
          type = 'мл'
          break;
        case 'мілілітри':
          type = 'мл'
          break;
        case 'milliliters':
          type = 'ml'
          break;
        case 'граммы':
          type = 'гр'
          break;
        case 'гр':
          type = 'гр'
          break;
        case 'грами':
          type = 'гр'
          break;
        case 'grams':
          type = 'grams'
          break;
        case 'мл':
          type = 'мл'
          break;
      }
      return type
    },
    addToBasket(product) {
      this.addBasket({
        select_count: 1,
        select_price: this.product.weights[0].price,
        select_volume: this.product.weights[0],
        ...product
      })
      this.$toasted.success(this.$t('successAddBasket'));
      // this.changeShowPopupAddBasket(true);
    },
    fastBuy() {
      this.changeFastBuy(true)
      this.setProduct(this.product)
    },
    addFavorite(product) {
      let obj = {}
      obj.product_id = product
      if (this.isAuthenticated) {
        this.addFavourite(this.product).then(() => {
          this.fetchFavoriteList()
          this.$toasted.success(this.$t('successAddFavourites'));
          this.like = true
        }).catch(error => {
          console.log(error);
          if (error.response.status === 422) {

          }
        });
      } else {
        this.changePopupRegistration(true);
      }
    },
    deleteFavorite(product) {
      let obj = {}
      obj.product_id = product
      this.removeItemFromFavourites(obj).then(() => {
        this.like = false
        this.fetchFavoriteList().then(() => {
          this.$toasted.success(this.$t('successDeletedFavourites'))
        });
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {

        }
      });
    },
  },

}
